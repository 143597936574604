import { AppointmentResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import {
  fromDate,
  getLocalTimeZone,
  toCalendarDate
} from "@internationalized/date";
import clsx from "clsx";
import { FC, default as React } from "react";
import compareTime from "lib/compareTime";
import CalendarIconSmall from "../../../icons/IconCalendarSmall";
import TimeIconSmall from "../../../icons/IconTimeSmall";
import { AppointmentSlot } from "./SchedulerBloc";

const SlotDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 1.5rem;
`;

const ConfirmInfoRow = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: baseline;
  font-weight: 500;
  letter-spacing: -0.32px;

  svg {
    display: block;
    transform: translateY(0.25rem);
  }

  .content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
`;

const Line = styled.div`
  padding-right: 0.5rem;
  &.strike {
    text-decoration: line-through;
    color: var(--Greys-Gray, #aaa7a0);
  }
`;

export const AppointmentSlotDetails: FC<{
  slot: AppointmentSlot;
  reschedule?: AppointmentResponse;
}> = ({ slot, reschedule }) => {
  const dateFormatTimeSimple: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric"
  };
  const dateFormatDate: Intl.DateTimeFormatOptions = {
    month: "short",
    weekday: "short",
    day: "numeric",
    year: "numeric"
  };

  const rescheduleFrom = reschedule?.start
    ? fromDate(new Date(reschedule.start), getLocalTimeZone())
    : undefined;
  const rescheduleTo = reschedule?.end
    ? fromDate(new Date(reschedule.end), getLocalTimeZone())
    : undefined;
  const differentDay = rescheduleFrom
    ? toCalendarDate(slot.from).compare(rescheduleFrom) !== 0
    : false;
  const differentTimeFrom = rescheduleFrom
    ? compareTime(slot.from, rescheduleFrom) !== 0
    : false;
  const differentTimeTo = rescheduleTo
    ? compareTime(slot.to, rescheduleTo) !== 0
    : false;

  const differentTime = differentTimeFrom || differentTimeTo;

  return (
    <SlotDetails className="slot-details">
      <ConfirmInfoRow>
        <div aria-label="Date" className="icon">
          <CalendarIconSmall />
        </div>
        <div className="content">
          {reschedule && differentDay && (
            <Line className={clsx({ strike: Boolean(reschedule) })}>
              <span>
                {new Date(reschedule.start).toLocaleDateString(
                  "en-US",
                  dateFormatDate
                )}
              </span>
            </Line>
          )}
          <Line>
            <span>
              {slot.from.toDate().toLocaleDateString("en-US", dateFormatDate)}
            </span>
          </Line>
        </div>
      </ConfirmInfoRow>
      <ConfirmInfoRow>
        <div aria-label="Time" className="icon">
          <TimeIconSmall />
        </div>
        <div className="content">
          {reschedule && differentTime && (
            <Line className={clsx({ strike: Boolean(reschedule) })}>
              <span>
                {new Date(reschedule.start).toLocaleTimeString(
                  "en-US",
                  dateFormatTimeSimple
                )}
                {" – "}
                {new Date(reschedule.end).toLocaleTimeString(
                  "en-US",
                  dateFormatTimeSimple
                )}
              </span>
            </Line>
          )}
          <Line>
            <span>
              {slot.from
                .toDate()
                .toLocaleTimeString("en-US", dateFormatTimeSimple)}
              {" – "}
              {slot.to
                .toDate()
                .toLocaleTimeString("en-US", dateFormatTimeSimple)}
            </span>
          </Line>
        </div>
      </ConfirmInfoRow>
    </SlotDetails>
  );
};

export default AppointmentSlotDetails;
