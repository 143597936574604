import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import Button from "atom/button/Button";
import React, {
    FC
} from "react";
import {
    AppointmentSlotGroup,
    SchedulerBloc
} from "./SchedulerBloc";

const SlotButtonWrap = styled.div`
  button {
    --btn-padding-left: 0.2rem !important;
    --btn-padding-right: 0.2rem !important;
    --btn-height: 2.5rem !important;
    outline: none;
    @media (min-width: 400px) {
      --btn-height: 3rem !important;
    }
  }

  button[data-theme="transparent"] {
    --inner-background: white;
    @media (pointer: fine) {
      &:hover {
        --inner-background: white;
      }
    }
  }
`;

const SlotPreview: FC<{ slot: AppointmentSlotGroup }> = ({ slot }) => {
  const [{ selectedSlotGroup }, { setSelectedSlotGroup }] =
    useBloc(SchedulerBloc);
  const from = slot.from
    .toDate()
    .toLocaleString("en-US", { hour: "numeric", minute: "numeric" });

  const isSelected =
    selectedSlotGroup &&
    selectedSlotGroup.from.compare(slot.from) === 0 &&
    selectedSlotGroup.to.compare(slot.to) === 0;

  return (
    <SlotButtonWrap className="slot-button-wrap">
      <Button
        onPress={() => setSelectedSlotGroup(slot)}
        outline
        fullWidth
        theme={isSelected ? "sunrise" : "transparent"}
        hideArrow
        active={isSelected}
      >
        {from}
      </Button>
    </SlotButtonWrap>
  );
};

export default SlotPreview;
