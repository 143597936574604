import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import React, { FC, useMemo } from "react";
import { AppointmentSlotGroup, SchedulerBloc } from "./SchedulerBloc";
import SlotPreview from "./SlotPreview";

const PickSlotGrid = styled.div`
  gap: 0.5rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 90px), 1fr));
  @media (min-width: 280px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PickSlot: FC = () => {
  const [{ selectedDate }, { getSlotsForSelectedDate }] =
    useBloc(SchedulerBloc);

  const slotsForDay = useMemo<AppointmentSlotGroup[]>(getSlotsForSelectedDate, [
    selectedDate
  ]);

  return (
    <PickSlotGrid>
      {slotsForDay.map((s) => (
        <SlotPreview key={`${s.from.toString()}-${s.to.toString()}`} slot={s} />
      ))}
    </PickSlotGrid>
  );
};

export default PickSlot;
