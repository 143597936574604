import { AppointmentParticipantResponse } from "@9amhealth/openapi";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import { translate } from "lib/blocs/TranslateionBloc";
import React, { FC, ReactNode } from "react";
import { Button as AriaButton } from "react-aria-components";
import IconChevronRightGray from "../../../icons/IconChevronRightGray";
import {
  AppointmentSlot,
  ScheduleAppointmentTypes,
  SchedulerBloc
} from "./SchedulerBloc";

const ProviderButtonWrap = styled.div`
  position: relative;

  button {
    background: transparent;
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem 0.5rem;
    user-select: none;

    @media (pointer: fine) {
      &:hover {
        background: #0001;
        border-radius: 0.5rem;
      }
    }
  }

  .provider-item {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .avatar {
    width: 44px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--color-cream-darker);
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-border {
      background: var(--Greys-White, #fff);
    }
  }

  .details {
    display: flex;
    flex-direction: column;
  }

  h5 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.28px;
    margin: 0;
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    margin: 0;
  }

  svg {
    margin-left: auto;
  }

  &:after {
    content: "";
    height: 1px;
    left: 0.5rem;
    right: 0.5rem;
    background: #0002;
    position: absolute;
    bottom: 0;
  }

  &:last-of-type:after {
    content: none;
  }

  & button[data-disabled="true"] {
    svg {
      opacity: 0;
    }
  }
`;

const ProviderPreview: FC<{
  slot: AppointmentSlot;
  avatar?: (id: string) => ReactNode;
  disabled?: boolean;
  appointmentType?: ScheduleAppointmentTypes;
}> = ({ slot, avatar, disabled, appointmentType }) => {
  const [, { setSelectedSlot }] = useBloc(SchedulerBloc);

  const title = (participant: AppointmentParticipantResponse) =>
    appointmentType
      ? translate("appointment.name", {
          context: appointmentType.toLowerCase().replace("_", "-")
        })
      : participant.displayName;

  const description = (participant: AppointmentParticipantResponse) =>
    appointmentType
      ? translate("appointment.with", {
          name: participant.displayName
        })
      : participant.displayRole;

  return (
    <ProviderButtonWrap
      className="slot-button-wrap"
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <AriaButton onPress={() => setSelectedSlot(slot)} isDisabled={disabled}>
        {slot.participants.map((participant) => (
          <div className="provider-item" key={participant.userId}>
            <div className="avatar">
              {avatar ? avatar(participant.userId) : null}
            </div>
            <div className="details">
              <h5>{title(participant)}</h5>
              <p>{description(participant)}</p>
            </div>
            <IconChevronRightGray />
          </div>
        ))}
      </AriaButton>
    </ProviderButtonWrap>
  );
};

export default ProviderPreview;
