import { AppointmentResponse } from "@9amhealth/openapi";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import { DateValue } from "@internationalized/date";
import Button from "atom/button/Button";
import clsx from "clsx";
import React, { FC, ReactNode, useEffect, useRef } from "react";
import { useDatePicker } from "react-aria";
import { useDatePickerState } from "react-stately";
import AppointmentSlotDetails from "./AppointmentSlotDetails";
import PickSlot from "./PickSlot";
import ProviderPreview from "./ProviderPreview";
import { ScheduleAppointmentTypes, SchedulerBloc } from "./SchedulerBloc";
import SchedulerCalendar from "./SchedulerCalendar";
import ReuseProvider from "./ReuseProvider";
import { translate } from "lib/blocs/TranslateionBloc";

const PickWrap = styled.div``;

const PickDate = styled.div``;

const PickProvider = styled.div`
  display: flex;
  flex-direction: column;
`;

const ConfirmSlot = styled.div`
  --pad: calc(4rem + var(--ion-safe-area-bottom, 0px));
  min-height: calc(60vh - var(--pad));
  min-height: calc(70dvh - var(--pad));
  display: flex;
  flex-direction: column;
  button {
    margin-top: auto;
  }
`;

const SlotScheduleInfo = styled.div`
  margin: 1rem 0 1.5rem;
`;

const ConfirmSlotContent = styled.span`
  max-height: calc(60vh- 7rem);
  max-height: calc(70dvh - 7rem);
  overflow-y: auto;
`;

type SchedulerPropsBase = {
  view:
    | "pick-date"
    | "pick-slot"
    | "confirm-slot"
    | "pick-provider"
    | "reuse-provider";
  avatar?: (id: string) => ReactNode;
  handleBookAppointment?: () => void;
  scheduleInfo?: ReactNode;
  bookAppointmentText?: ReactNode;
  type?: ScheduleAppointmentTypes;
  rescheduleAppointment?: AppointmentResponse;
  requestProviders?: string[];
  id?: string;
  name?: string;
  onClose?: () => void;
};

type SchedulerPropsPickDate = SchedulerPropsBase & {
  view: "pick-date";
  type: ScheduleAppointmentTypes;
};

type SchedulerPropsPickSlot = SchedulerPropsBase & {
  view: "pick-slot";
};

type SchedulerPropsReuseProvider = SchedulerPropsBase & {
  view: "reuse-provider";
  id: string;
  name: string;
  onClose: () => void;
  avatar: (id: string) => ReactNode;
};

type SchedulerPropsPickProvider = SchedulerPropsBase & {
  view: "pick-provider";
  avatar: (id: string) => ReactNode;
};

type SchedulerPropsConfirmSlot = SchedulerPropsBase & {
  view: "confirm-slot";
  avatar: (id: string) => ReactNode;
  handleBookAppointment: () => void;
  scheduleInfo: ReactNode;
  bookAppointmentText: ReactNode;
};

const Scheduler: FC<
  | SchedulerPropsPickDate
  | SchedulerPropsPickSlot
  | SchedulerPropsConfirmSlot
  | SchedulerPropsPickProvider
  | SchedulerPropsReuseProvider
> = ({
  view,
  avatar,
  handleBookAppointment,
  scheduleInfo,
  bookAppointmentText,
  type,
  rescheduleAppointment,
  name,
  onClose,
  id,
  requestProviders
}) => {
  const [
    { selectedDate, availableSlotGroups, selectedSlot, selectedSlotGroup },
    {
      setFocusedDate,
      initDatePickerView,
      handleDateFocusChanged,
      appointmentType, currentCacheKey
    }
  ] = useBloc(SchedulerBloc);

  useEffect(() => {
    if (view !== "pick-date") return;
    void initDatePickerView({
      type,
      rescheduleAppointment,
      requestProviders
    }).catch((e: unknown) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }, [type, view, rescheduleAppointment, requestProviders]);

  const slotsSectionRef = useRef<HTMLDivElement>(null);

  const handleDateChanged = (d: DateValue) => {
    setFocusedDate(d);
    setTimeout(() => {
      slotsSectionRef.current?.scrollIntoView({
        behavior: "smooth"
      });
    }, 60);
  };

  const state = useDatePickerState({});
  const elRef = React.useRef(null);

  const { calendarProps } = useDatePicker({
    label: translate("appointment.scheduler.pickDate")
  }, state, elRef);

  const availableDays: Set<string> = new Set();

  for (const s of availableSlotGroups) {
    availableDays.add(SchedulerBloc.dateToString(s.from));
  }

  return (
    <>
      {view === "reuse-provider" && <ReuseProvider
        avatar={avatar}
        id={id}
        name={name}
        onClose={onClose}
      />}
      {view === "pick-date" && (
        <>
          <PickWrap
            className={clsx({
              dateIsSelected: Boolean(selectedDate)
            })}
          >
            <PickDate>
              <SchedulerCalendar
                {...calendarProps}
                availableDays={availableDays}
                onChange={handleDateChanged}
                autoFocus={false}
                value={selectedDate}
                onFocusChange={handleDateFocusChanged}
                key={currentCacheKey}
              />
            </PickDate>
          </PickWrap>
        </>
      )}
      {view === "pick-slot" && selectedDate && <PickSlot />}
      {view === "pick-provider" && selectedSlotGroup && (
        <>
          <PickProvider>
            {selectedSlotGroup.slots.map((s) =>
              s.participants.map((p) => (
                <ProviderPreview
                  key={`${p.userId}-${s.from.toString()}`}
                  slot={s}
                  avatar={avatar}
                />
              ))
            )}
          </PickProvider>
        </>
      )}
      {view === "confirm-slot" && selectedSlot && (
        <>
          <ConfirmSlot className="confirm-slot">
            <ConfirmSlotContent>
              {selectedSlot.participants.map(() => (
                <ProviderPreview
                  slot={selectedSlot}
                  avatar={avatar}
                  key={selectedSlot.participants[0].userId}
                  appointmentType={appointmentType}
                  disabled
                />
              ))}
              <AppointmentSlotDetails
                slot={selectedSlot}
                reschedule={rescheduleAppointment}
              />
              {scheduleInfo && (
                <SlotScheduleInfo>{scheduleInfo}</SlotScheduleInfo>
              )}
            </ConfirmSlotContent>
            <Button onPress={handleBookAppointment} fullWidth>
              {bookAppointmentText}
            </Button>
          </ConfirmSlot>
        </>
      )}
    </>
  );
};

export default Scheduler;
