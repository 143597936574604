import React from "react";
import styled from "@emotion/styled";
import { Button } from "@9amhealth/shared";
import Avatar from "src/ui/components/Avatar/Avatar";
import Translate from "../Translate/Translate";

interface ProviderReconnectModalProps {
  providerName: string;
  providerId: string;
  onYes: () => void;
  onNo: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  max-width: 28rem;
  margin: 0 auto;
  height: 100%;
`;

const AvatarContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: -0.56px;
  line-height: 120%;
  text-wrap: pretty;
  margin-bottom: 1.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  width: 100%;
  max-width: 24rem;

  button:nth-of-type(1) {
    z-index: 2;
  }
  button:nth-of-type(2) {
    z-index: 1;
  }
`;

export const ProviderReconnectModal: React.FC<ProviderReconnectModalProps> = ({
  providerName,
  providerId,
  onYes,
  onNo
}) => {
  return (
    <ModalContainer>
      <AvatarContainer>
        <Avatar id={providerId} size="large" />
      </AvatarContainer>

      <Title>
        <Translate
          msg="provider_reconnect_modal_title"
          variables={{
            name: providerName
          }}
        />
      </Title>

      <ButtonContainer>
        <Button fullWidth theme="card" onPress={onYes} hideArrow>
          <Translate msg="yes" />
        </Button>

        <Button fullWidth theme="card" onPress={onNo} hideArrow>
          <Translate msg="no" />
        </Button>
      </ButtonContainer>
    </ModalContainer>
  );
};

export default ProviderReconnectModal;
