import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { translate } from "lib/blocs/TranslateionBloc";

interface ReuseProviderProps {
  avatar: (id: string) => ReactNode;
  id: string;
  name: string;
  onClose?: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 80px;
  height: 60px;
  position: relative;
  width: fit-content;
  margin: -0.5rem auto 0.5rem;
  z-index: 5;

  &:before {
    content: "";
    z-index: -2;
    position: absolute;
    background: var(--gradient-sunrise, #80AEFF);
    inset: 0;
    border-radius: 80px;
  }

  &:after {
    content: "";
    z-index: -1;
    position: absolute;
    background: white;
    inset: 2px;
    border-radius: 80px;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .avatar-border {
    --inset: 0;
  }
`;

const Name = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  font-size: 20px;
  line-height: 1;
  color: #666;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #333;
  }
`;

const ReuseProvider: React.FC<ReuseProviderProps> = ({
  avatar,
  name,
  onClose,
  id
}) => {
  return (
    <Container>
      <ProfileSection>
        {avatar(id)}
        <Name>{name}</Name>
      </ProfileSection>
      <CloseButton onClick={onClose} aria-label={translate('deselect', { name })}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          fill="none"
        >
          <path
            stroke="#212121"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeOpacity=".8"
            strokeWidth="1.5"
            d="m17.5 7-10 10m0-10 10 10"
          />
        </svg>
      </CloseButton>
    </Container>
  );
};

export default ReuseProvider;
