import {
  AppointmentControllerService,
  AppointmentParticipantResponse,
  AppointmentResponse,
  AppointmentSchedulingControllerService,
  AvailableSlotResponse,
  ProviderAdditionalInfoResponse,
  SuccessAppointmentResponse
} from "@9amhealth/openapi";
import {
  CalendarDate,
  DateValue,
  endOfMonth,
  fromDate,
  getLocalTimeZone,
  startOfMonth,
  toCalendarDate,
  toCalendarDateTime,
  today,
  ZonedDateTime
} from "@internationalized/date";
import { Cubit } from "blac-next";

export type AppointmentSlot = {
  from: ZonedDateTime;
  to: ZonedDateTime;
  componentIdentifier: string;
  participants: Array<AppointmentParticipantResponse>;
};

export type AppointmentSlotGroup = {
  from: ZonedDateTime;
  to: ZonedDateTime;
  slots: AppointmentSlot[];
};

export type SchedulerState = {
  selectedSlot?: AppointmentSlot;
  selectedSlotGroup?: AppointmentSlotGroup;
  selectedDate?: DateValue;
  availableSlotGroups: AppointmentSlotGroup[];
  /**
   * @description
   * null means we dont have a previous provider
   * undefined means we have not checked yet
   */
  previousProvider?: ProviderAdditionalInfoResponse | null;
  loading: boolean;
};

export type SchedulerStep = "confirm" | "pick-date" | "pick-slot";

export type SchedulerProps = {
  availableSlots: AppointmentSlot[];
};

export type ScheduleAppointmentTypes = Parameters<
  typeof AppointmentSchedulingControllerService.getAvailableSlots
>[0];

export class SchedulerBloc extends Cubit<SchedulerState, SchedulerProps> {
  static dateToString = (date: DateValue) => {
    return date.toString().split("T")[0];
  };

  availableSlots: AppointmentSlot[] = [];
  daysPerRequest = 5;
  currentAppointmentType?: ScheduleAppointmentTypes;
  rescheduleAppointment?: AppointmentResponse;
  requestProviders?: string[];
  currentMonth = today(getLocalTimeZone()).month;

  constructor() {
    super({
      loading: false,
      availableSlotGroups: []
    });
  }

  get appointmentType(): ScheduleAppointmentTypes | undefined {
    return this.currentAppointmentType ?? this.rescheduleAppointment?.type;
  }

  setProps = (props: SchedulerProps) => {
    this.props = props;
  };

  focusedDate?: DateValue;
  setFocusedDate = (d: DateValue) => {
    this.focusedDate = d;
    this.pickFocusedDate();
  };

  pickFocusedDate = () => {
    if (!this.focusedDate) {
      throw new Error("No date focused");
    }

    this.patch({
      selectedDate: this.focusedDate,
      selectedSlot: undefined,
      selectedSlotGroup: undefined
    });
  };

  clearSelectedDate = () => {
    this.patch({
      selectedDate: undefined,
      selectedSlot: undefined,
      selectedSlotGroup: undefined
    });
  };

  getActiveStep = () => {
    const { selectedDate, selectedSlot } = this.state;

    if (!selectedDate) {
      return "pick-date";
    }

    if (!selectedSlot) {
      return "pick-slot";
    }

    return "confirm";
  };

  getSlotsForDate = (date?: DateValue): AppointmentSlotGroup[] => {
    const selectedDate = date?.toDate(getLocalTimeZone()).toDateString();
    return this.state.availableSlotGroups.filter(
      (s) => selectedDate === s.from.toDate().toDateString()
    );
  };

  getSlotsForSelectedDate = (): AppointmentSlotGroup[] => {
    return this.getSlotsForDate(this.state.selectedDate);
  };

  setSelectedSlot = (slot: AppointmentSlot | undefined) => {
    let { selectedSlotGroup } = this.state;
    if (slot === undefined && selectedSlotGroup?.slots.length === 1) {
      selectedSlotGroup = undefined;
    }
    this.patch({ selectedSlot: slot, selectedSlotGroup });
  };

  setSelectedSlotGroup = (slotGroup: AppointmentSlotGroup | undefined) => {
    let selectedSlot = undefined;

    if (slotGroup && slotGroup.slots.length === 1) {
      selectedSlot = slotGroup.slots[0];
    }

    this.patch({ selectedSlotGroup: slotGroup, selectedSlot });
  };

  bookSelectedSlot = async (options: {
    rescheduleAppointment?: AppointmentResponse;
  }): Promise<AppointmentResponse> => {
    if (!this.currentAppointmentType) {
      throw new Error("No appointment type provided");
    }
    if (!this.state.selectedSlot) {
      throw new Error("No selected slot provided");
    }

    const { rescheduleAppointment } = options;
    const isReschedule = rescheduleAppointment !== undefined;

    const start = this.state.selectedSlot.from.toDate().toISOString();
    const end = this.state.selectedSlot.to.toDate().toISOString();
    const participantUserIds = this.state.selectedSlot.participants.map(
      (p) => p.userId
    );

    if (isReschedule) {
      const updatedAppointment =
        await AppointmentControllerService.updateMemberAppointment(
          rescheduleAppointment.id,
          {
            status: rescheduleAppointment.status,
            start,
            end,
            participantUserIds
          }
        );

      return updatedAppointment.data;
    } else {
      const newAppointment =
        await AppointmentSchedulingControllerService.scheduleAppointment(
          this.currentAppointmentType,
          {
            componentIdentifier: this.state.selectedSlot.componentIdentifier,
            start,
            end,
            participantUserIds
          }
        );

      return newAppointment.data;
    }
  };

  clearSelectedSlot = () => {
    this.patch({
      selectedSlot: undefined
    });
  };

  initDatePickerView = async (options: {
    type: ScheduleAppointmentTypes;
    rescheduleAppointment?: AppointmentResponse;
    participantUserIds?: string[];
    requestProviders?: string[];
  }): Promise<void> => {
    if (this.state.loading) return;
    this.currentAppointmentType = options.type;
    this.rescheduleAppointment = options.rescheduleAppointment;
    this.requestProviders = options.requestProviders;
    await this.loadAppointmentSlotsForCurrentMonth();
  };

  loadAppointmentSlotsForTimeRange = async (options: {
    type: ScheduleAppointmentTypes;
    start: CalendarDate;
    end: CalendarDate;
  }): Promise<void> => {
    const { type, start, end } = options;
    try {
      const startDate = toCalendarDateTime(start).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      const endDate = toCalendarDateTime(end).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });

      let providerList = this.rescheduleAppointment
        ? this.rescheduleAppointment.participants.map((p) => p.userId)
        : undefined;

      if (this.requestProviders) {
        providerList = this.requestProviders;
      }

      const availableSlots =
        await AppointmentSchedulingControllerService.getAvailableSlots(
          type,
          startDate.toDate(getLocalTimeZone()).toISOString(),
          endDate.toDate(getLocalTimeZone()).toISOString(),
          providerList
        );

      const slots = this.parseSlotsFromApiResponse(availableSlots.data);

      const mergedSlots = this.mergeSlots(slots);

      this.patch({
        availableSlotGroups: mergedSlots
      });
    } catch (e) {
      console.error(e);
    }
  };

  mergeSlots = (slots: AppointmentSlotGroup[]): AppointmentSlotGroup[] => {
    const mergedSlots: AppointmentSlotGroup[] = [
      ...this.state.availableSlotGroups,
      ...slots
    ];
    const slotMap = new Map<string, AppointmentSlotGroup>();

    mergedSlots.forEach((slot) => {
      const key = `${slot.from.toString()}-${slot.to.toString()}`;
      if (!slotMap.has(key)) {
        slotMap.set(key, slot);
      }
    });

    const uniqueSlots = [...slotMap.values()];

    return uniqueSlots;
  };

  get cacheKey() {
    return `${this.currentAppointmentType}-${this.rescheduleAppointment?.id}-${this.requestProviders?.join("-")}`;
  }

  loadCompleteForMonths = new Set<number>();
  currentCacheKey = "";
  loadAppointmentSlotsForCurrentMonth = async (): Promise<void> => {
    this.clearSelectedDate();
    if (this.currentCacheKey !== this.cacheKey) {
      this.patch({
        availableSlotGroups: [],
        selectedSlot: undefined,
        selectedSlotGroup: undefined,
        selectedDate: undefined
      });
      this.loadCompleteForMonths.clear();
      this.currentCacheKey = this.cacheKey;
    }
    if (this.loadCompleteForMonths.has(this.currentMonth)) {
      return;
    }
    this.patch({
      loading: true
    });
    const requestListForMonth: Parameters<
      typeof this.loadAppointmentSlotsForTimeRange
    >[0][] = [];

    const { currentMonth } = this;
    const dateNow = today(getLocalTimeZone());

    if (!this.currentAppointmentType) {
      throw new Error("No appointment type provided");
    }

    let start =
      currentMonth === dateNow.month
        ? dateNow
        : startOfMonth(dateNow).set({ month: currentMonth, day: 1 });

    let ended = false;
    let limit = 10;
    while (!ended && limit > 0) {
      limit--;
      const startCalendar = toCalendarDate(start);
      let end = startCalendar.add({ days: this.daysPerRequest });

      if (end.month !== currentMonth) {
        end = endOfMonth(start).add({ days: 1 });
        ended = true;
      }

      if (end.compare(start) < 0) {
        break;
      }

      requestListForMonth.push({
        start,
        end,
        type: this.currentAppointmentType
      });
      start = end;
    }

    await Promise.all(
      requestListForMonth.map((request) =>
        this.loadAppointmentSlotsForTimeRange(request)
      )
    );

    this.patch({
      loading: false
    });
    this.loadCompleteForMonths.add(currentMonth);
  };

  debouncedHandleDateFocusChanged: NodeJS.Timeout | number = 0;
  handleDateFocusChanged = (focusedDate: DateValue) => {
    clearTimeout(this.debouncedHandleDateFocusChanged);
    this.debouncedHandleDateFocusChanged = setTimeout(() => {
      const newFocusedMonth = focusedDate.month;
      if (this.currentMonth !== newFocusedMonth) {
        this.currentMonth = newFocusedMonth;
        void this.loadAppointmentSlotsForCurrentMonth();
      }
    }, 300);
  };

  datesAreEqual = (date1: DateValue, date2: DateValue): boolean => {
    const diff = date1.compare(date2);
    return diff === 0;
  };

  parseSlotsFromApiResponse = (
    slots: Array<AvailableSlotResponse>
  ): AppointmentSlotGroup[] => {
    // const participantFilter = this.rescheduleAppointment
    //   ? this.rescheduleAppointment.participants.map((p) => p.userId)
    //   : undefined;

    const validSlots = slots;

    const availableSlots = validSlots.map((slot) => {
      const from = fromDate(new Date(slot.start), getLocalTimeZone());
      const to = fromDate(new Date(slot.end), getLocalTimeZone());

      return {
        from,
        to,
        participants: slot.participants,
        componentIdentifier: slot.componentIdentifier
      } satisfies AppointmentSlot;
    });
    this.availableSlots = availableSlots;

    let groupedSlots: AppointmentSlotGroup[] = [];

    // group the slots by the start and end date
    availableSlots.forEach((slot) => {
      const { from } = slot;
      const { to } = slot;
      const slots = groupedSlots.find(
        (s) => this.datesAreEqual(s.from, from) && this.datesAreEqual(s.to, to)
      );
      if (slots) {
        slots.slots.push(slot);
      } else {
        groupedSlots.push({
          from,
          to,
          slots: [slot]
        });
      }
    });

    groupedSlots = groupedSlots.map((sg) => {
      // sort the slots by the first participant's name
      const sortedSlots = sg.slots.sort((a, b) => {
        const aName = a.participants[0].displayName?.toLowerCase() ?? "";
        const bName = b.participants[0].displayName?.toLowerCase() ?? "";
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      });
      return {
        ...sg,
        slots: sortedSlots
      };
    });

    return groupedSlots;
  };

  checkPreviousProvider = async (appointmentType: ScheduleAppointmentTypes) => {
    this.patch({
      loading: true
    });

    try {
      const response =
        await AppointmentControllerService.getMostRecentAppointmentForType(
          appointmentType
        );

      // TODO: Remove this typecasting once the API is updated
      const data = response.data as
        | SuccessAppointmentResponse["data"]
        | undefined;
      const previousProvider = data?.providerAdditionalInfoResponse ?? null;

      this.patch({
        previousProvider
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.patch({
        loading: false
      });
    }
  };
}
