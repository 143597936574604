import {
  CalendarDate,
  createCalendar,
  DateValue,
  endOfMonth,
  getLocalTimeZone,
  getWeeksInMonth,
  now,
  startOfMonth
} from "@internationalized/date";
import {
  CalendarCellHeader,
  CalendarContainer,
  CalendarTable,
  CalendarTitle,
  Cell,
  Header,
  StyledButton
} from "atom/scheduler/schedulerComponents";
import clsx from "clsx";
import { default as React } from "react";
import {
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useLocale
} from "react-aria";
import { CalendarProps } from "react-aria-components";
import { CalendarState, useCalendarState } from "react-stately";
import { SchedulerBloc } from "./SchedulerBloc";

function CalendarGrid({ state, ...props }: { state: CalendarState }) {
  const { locale } = useLocale();
  const { gridProps, weekDays } = useCalendarGrid(
    { ...props, weekdayStyle: "short" },
    state
  );

  // Get the number of weeks in the month, so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <CalendarTable {...gridProps}>
      {weekDays.map((day, index) => (
        <CalendarCellHeader key={index}>
          {day.substring(0, 2)}
        </CalendarCellHeader>
      ))}
      {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
        <React.Fragment key={weekIndex}>
          {state
            .getDatesInWeek(weekIndex)
            .map((date, i) =>
              date ? (
                <CalendarCell key={i} state={state} date={date} />
              ) : (
                <div key={i} />
              )
            )}
        </React.Fragment>
      ))}
    </CalendarTable>
  );
}

function CalendarCell({
  state,
  date
}: {
  state: CalendarState;
  date: CalendarDate;
}) {
  const ref = React.useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate
  } = useCalendarCell({ date }, state, ref);

  return (
    <div {...cellProps}>
      <Cell
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={clsx({
          selected: isSelected,
          hidden: isDisabled,
          unavailable: isUnavailable,
          available: !isUnavailable
        })}
      >
        {formattedDate}
      </Cell>
    </div>
  );
}

function SchedulerCalendar(
  props: {
    availableDays: Set<string>;
  } & CalendarProps<DateValue>
) {
  const { locale } = useLocale();
  const minValue = startOfMonth(now(getLocalTimeZone()));
  const maxValue = endOfMonth(minValue.add({ months: 2 }));
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    isDateUnavailable: (d) => {
      return !props.availableDays.has(SchedulerBloc.dateToString(d));
    },
    minValue,
    maxValue
  });

  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar({ ...props }, state);

  return (
    <CalendarContainer {...calendarProps} className="calendar">
      <Header>
        <StyledButton {...prevButtonProps} data-dir="prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#212121"
              fillRule="evenodd"
              stroke="#212121"
              strokeLinejoin="round"
              strokeOpacity=".6"
              d="M13.6 12.7c.4-.4.4-1 0-1.4L9 6.7a.5.5 0 1 1 .7-.7l5.3 5.3c.4.4.4 1 0 1.4L9.7 18a.5.5 0 0 1-.7-.7l4.6-4.6Z"
              clipRule="evenodd"
            />
          </svg>
        </StyledButton>
        <CalendarTitle>{title}</CalendarTitle>
        <StyledButton {...nextButtonProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#212121"
              fillRule="evenodd"
              stroke="#212121"
              strokeLinejoin="round"
              strokeOpacity=".6"
              d="M13.6 12.7c.4-.4.4-1 0-1.4L9 6.7a.5.5 0 1 1 .7-.7l5.3 5.3c.4.4.4 1 0 1.4L9.7 18a.5.5 0 0 1-.7-.7l4.6-4.6Z"
              clipRule="evenodd"
            />
          </svg>
        </StyledButton>
      </Header>
      <CalendarGrid state={state} />
    </CalendarContainer>
  );
}
export default SchedulerCalendar;
